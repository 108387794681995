<template>
  <div>
    <eden-page-header :title="'Service Dashboard'" >
      <!-- <template slot="actions">
          <el-button
            type="plain"
            icon="eden-icon-upload"
            class="ml-10"
            :disabled="loading"
            @click="exportAction"
          >
            Export
          </el-button>
        </template> -->
    </eden-page-header>

    <el-tabs v-model="tab" @tab-click="updateRoute">
      <el-tab-pane
        v-for="(tab, i) in tabs"
        :key="i"
        :label="tab.label"
        :name="tab.name"
      >
        <component :is="`dashboard-${tab.name}`" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  components: {
    DashboardBeauty: () =>
      import("@/components/Dashboard/Beauty/DashboardBeauty"),
    DashboardCleaning: () =>
      import("@/components/Dashboard/Cleaning/DashboardCleaning"),
    DashboardLaundry: () =>
      import("@/components/Dashboard/Laundry/DashboardLaundry"),
    DashboardMeal: () => import("@/components/Dashboard/Meal/DashboardMeal"),
  },
  data() {
    return {
      tab: "meal",
      loading: false,
    };
  },
  computed: {
    services() {
      return this.$store.getters.services;
    },
    tabs() {
      return this.services.map((service) => {
        return {
          label: service.slug === "meal" ? "Food" : service.service_name,
          name: service.slug,
        };
      });
    },
  },
  created() {
    let { t } = this.$route.query;
    this.tab = t ? t : "meal";
  },
  methods: {
    updateRoute(tab) {
      const currentTab = this.$route.query.t;

      if (currentTab === this.tab) {
        return false;
      }
      this.$router.push({
        name: "dashboard.index",
        query: { t: tab.name },
      });
    },
    // exportAction() {
    //   const currentTab = this.$route.query.t; 
    //   if (currentTab === this.tab) {
    //     console.log(currentTab, "this tab")
    //   }
    // }
  },
};
</script>
